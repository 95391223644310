import * as React from 'react';
import { useState } from 'react';

import {
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useToast,
    useDisclosure,
} from '@chakra-ui/react';
import { useSafeAppsSDK } from '@gnosis.pm/safe-apps-react-sdk';
import { isAddress } from 'ethers/lib/utils';

export default function AddMember(props) {
    const [memberAddress, setMemberAddress] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const { sdk } = useSafeAppsSDK();
    const initialRef = React.useRef()


    const addMember = async () => {
        if (!isAddress(memberAddress)) {
            toast({
                title: 'Not an address',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })

            return
        }

        const exists = await props.contract.members(memberAddress)
        if (!exists) {
            const txs = [
                {
                    to: props.contract.address,
                    value: '0',
                    data: props.contract.interface.encodeFunctionData('addMember', [memberAddress]),
                }
            ]

            try {
                const txn = await sdk.txs.send({ txs })
                console.log(txn)
            } catch (e) {
                toast({
                    title: 'Something went wrong',
                    description: e.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        } else {
            toast({
                title: 'Member already exists',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
    }

    return (
        <>
            <Button bgColor='brand.300' onClick={onOpen}>Add Member</Button>

            <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bgColor='brand.100' >
                    <ModalHeader color='brand.400'>Add Member</ModalHeader>
                    <ModalCloseButton bgColor='brand.300' color='brand.400' />
                    <ModalBody>
                        <Input color='brand.400' ref={initialRef} value={memberAddress} placeholder='Wallet Address' onChange={(event) => setMemberAddress(event.target.value)}></Input>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='brand.400' bgColor='brand.300' mr={3} onClick={addMember}>
                            Add
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}