import * as React from 'react';
import { useEffect, useState } from 'react';

import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useToast,
    useDisclosure,
    Text,
    Code,
} from '@chakra-ui/react';

import { BigNumber } from 'ethers';
import { parseEther, formatEther, isAddress } from 'ethers/lib/utils';
import { useSafeAppsSDK } from '@gnosis.pm/safe-apps-react-sdk';

export default function KickMember(props) {
    const [kickCost, setKickCost] = useState(BigNumber.from(0).toString());
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const { sdk } = useSafeAppsSDK();



    useEffect(() => {
        async function getMemberKickCost() {
            const numberOfTokens = (await props.contract.tokenCounter.call())

            let totalKickCost = BigNumber.from('0')

            for (let i = 0; i < numberOfTokens; i++) {
                const numberOfOwnedTokens = await props.contract.balanceOf(props.address, i)
                if (numberOfOwnedTokens > 0) {
                    const tokenMintPrice = (await props.contract.tokens(i))[0]
                    totalKickCost = totalKickCost.add((numberOfOwnedTokens.mul(tokenMintPrice)))
                }
            }

            setKickCost(formatEther(totalKickCost))
        }

        getMemberKickCost()
    }, [])

    const kickMember = async () => {
        if (!isAddress(props.address)) {
            toast({
                title: 'Not an address',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })

            return
        }

        const txs = [
            {
                to: props.contract.address,
                value: parseEther(kickCost),
                data: props.contract.interface.encodeFunctionData('kick', [props.address]),
            }
        ]

        try {
            const txn = await sdk.txs.send({ txs })
            console.log(txn)
        } catch (e) {
            toast({
                title: 'Something went wrong',
                description: e.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
    }

    return (
        <>
            <Button bgColor='brand.300' onClick={onOpen}>Kick</Button>

            <Modal isOpen={isOpen} onClose={onClose} size='lg'>
                <ModalOverlay />
                <ModalContent bgColor='brand.100' color='brand.400'>
                    <ModalHeader>Kick Member</ModalHeader>
                    <ModalCloseButton bgColor='brand.300' color='brand.400' />
                    <ModalBody>
                        <Text>Are you sure you want to kick <Code>{props.address}</Code>?</Text>
                        <Text paddingTop={2}>This will cost Ξ{kickCost}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='brand.400' bgColor='brand.300' mr={3} onClick={kickMember}>
                            Kick
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}