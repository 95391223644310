import {
    VStack,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Flex,
    FormControl,
    FormLabel,
    FormHelperText,
    NumberInputField,
    NumberInput,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Divider,
    useToast,
} from "@chakra-ui/react"

import { useState } from "react"
import { useSafeAppsSDK } from "@gnosis.pm/safe-apps-react-sdk"

import { parseEther } from "ethers/lib/utils"

export default function EditMintModal(props) {
    const [mintPrice, setMintPrice] = useState(0)
    const [startDate, setStartDate] = useState(0)
    const [endDate, setEndDate] = useState(0)
    const toast = useToast()
    const { sdk } = useSafeAppsSDK()

    const editFundingRound = async () => {
        if (!mintPrice || !startDate || !endDate) {
            toast({
                title: "Please fill in all fields",
                status: "error",
                duration: 3000,
                isClosable: true,
            })

            return
        }

        const startDateUnix = Math.floor(new Date(startDate).getTime() / 1000)
        const endDateUnix = Math.floor(new Date(endDate).getTime() / 1000)

        const txs = [
            {
                to: props.contract.address,
                value: "0",
                data: props.contract.interface.encodeFunctionData(
                    "editFundingRound",
                    [
                        props.id,
                        parseEther(mintPrice),
                        startDateUnix,
                        endDateUnix,
                    ]
                ),
            },
        ]

        try {
            const txn = await sdk.txs.send({ txs })
            console.log(txn)
        } catch (e) {
            toast({
                title: "Something went wrong",
                description: e.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent bgColor="brand.100" color="brand.400">
                <ModalHeader>Edit {props.name}</ModalHeader>
                <ModalCloseButton bgColor="brand.300" color="brand.400" />
                <ModalBody>
                    <Flex p={5}>
                        <FormControl>
                            <FormLabel>Mint Price</FormLabel>
                            <NumberInput min={0}>
                                <NumberInputField
                                    id="amount"
                                    value={mintPrice}
                                    onChange={event => {
                                        event.target.value =
                                            event.target.value.replace(
                                                /[^0-9]*/,
                                                ""
                                            )
                                        setMintPrice(event.target.value)
                                    }}
                                />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                            <FormHelperText>
                                Enter value in Ether
                            </FormHelperText>
                            <Divider marginTop={5} marginBottom={5} />
                            <VStack>
                                <FormLabel>Start Time</FormLabel>
                                <Input
                                    type="datetime-local"
                                    _placeholder={{ color: "lightgray" }}
                                    placeholder="Start time"
                                    onChange={event => {
                                        setStartDate(event.target.value)
                                    }}
                                />
                                <FormHelperText>
                                    Select date and time minting will start
                                </FormHelperText>
                            </VStack>
                            <Divider marginTop={5} marginBottom={5} />
                            <VStack>
                                <FormLabel>End Time</FormLabel>
                                <Input
                                    type="datetime-local"
                                    onChange={event => {
                                        setEndDate(event.target.value)
                                    }}
                                />
                                <FormHelperText>
                                    Select date and time minting will end
                                </FormHelperText>
                            </VStack>

                            <Divider marginTop={5} marginBottom={5} />
                        </FormControl>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button
                        backgroundColor="brand.300"
                        color={"white"}
                        onClick={editFundingRound}
                    >
                        Edit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
