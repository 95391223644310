import { ColorModeScript, ModalContent } from '@chakra-ui/react';
import SafeProvider from '@gnosis.pm/safe-apps-react-sdk';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { Global } from '@emotion/react';
import { MoralisProvider } from 'react-moralis';

const Fonts = () => {
  return <Global styles={`
  @font-face {
    font-family: 'Eina';
    font-style: normal;
    src: local('Eina'), url('./fonts/Eina/Eina01-Regular.ttf') format('woff');
  }
  `} />
}

const theme = extendTheme({
  colors: {
    brand: {
      100: '#313133',
      200: '#282729',
      300: '#7047EB',
      400: '#FFFFFF'
    }
  },
  fonts: {
    body: 'Eina',
  },
  styles: {
    global: {
      body: {
        bg: 'brand.100'
      }
    }
  },
  components: {
    Modal: {
      defaultProps: {
        size: 'xl'
      }
    },
  }
})



ReactDOM.render(
  <StrictMode>
    <SafeProvider>
      <MoralisProvider
        serverUrl={process.env.REACT_APP_MORALIS_SERVER_URL}
        appId={process.env.REACT_APP_MORALIS_APP_ID}>
        <ColorModeScript />
        <ChakraProvider theme={theme}>
          <Fonts />
          <Main />
        </ChakraProvider>
      </MoralisProvider>
    </SafeProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
