import {
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    FormControl,
    FormLabel,
    NumberInputField,
    NumberInput,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Divider,
    useToast,
    HStack,
} from "@chakra-ui/react"

import { useState } from "react"
import { useSafeAppsSDK } from "@gnosis.pm/safe-apps-react-sdk"

export default function SettingsModal(props) {
    const [tokenSupply, setTokenSupply] = useState()
    const [metadata, setMetadata] = useState()
    const [mintLimit, setMintLimit] = useState()
    const toast = useToast()
    const { sdk } = useSafeAppsSDK()

    const editTokenMaximum = async () => {
        if (!tokenSupply) {
            toast({
                title: "Token supply is required",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
            return
        }

        const exists = (await props.contract.tokens(props.id)).exists
        if (exists) {
            const txs = [
                {
                    to: props.contract.address,
                    value: "0",
                    data: props.contract.interface.encodeFunctionData(
                        "editTokenMintMaximum",
                        [props.id, tokenSupply]
                    ),
                },
            ]

            try {
                const txn = await sdk.txs.send({ txs })
                console.log(txn)
            } catch (e) {
                toast({
                    title: "Something went wrong",
                    description: e.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                })
            }
        } else {
            toast({
                title: "Token does not exist",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const editTokenMintLimit = async () => {
        if (!mintLimit) {
            toast({
                title: "Mint limit is required",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
            return
        }

        const exists = (await props.contract.tokens(props.id)).exists
        if (exists) {
            const txs = [
                {
                    to: props.contract.address,
                    value: "0",
                    data: props.contract.interface.encodeFunctionData(
                        "editTokenMaxAmount",
                        [props.id, mintLimit]
                    ),
                },
            ]

            try {
                const txn = await sdk.txs.send({ txs })
                console.log(txn)
            } catch (e) {
                toast({
                    title: "Something went wrong",
                    description: e.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                })
            }
        } else {
            toast({
                title: "Token does not exist",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const editTokenIPFS = async () => {
        if (!metadata) {
            toast({
                title: "Metadata is required",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
            return
        }

        const exists = (await props.contract.tokens(props.id)).exists
        if (exists) {
            const txs = [
                {
                    to: props.contract.address,
                    value: "0",
                    data: props.contract.interface.encodeFunctionData(
                        "editTokenIPFS",
                        [props.id, metadata]
                    ),
                },
            ]

            try {
                const txn = await sdk.txs.send({ txs })
                console.log(txn)
            } catch (e) {
                toast({
                    title: "Something went wrong",
                    description: e.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                })
            }
        } else {
            toast({
                title: "Token does not exist",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent bgColor="brand.100" color="brand.400">
                <ModalHeader>{props.name} Settings</ModalHeader>
                <ModalCloseButton bgColor="brand.300" color="brand.400" />
                <ModalBody>
                    <Flex p={5}>
                        <FormControl>
                            <FormLabel>Change Token Supply</FormLabel>
                            <HStack>
                                <NumberInput min={0} flex="1">
                                    <NumberInputField
                                        id="amount"
                                        value={tokenSupply}
                                        placeholder="maximum number of tokens available"
                                        onChange={event => {
                                            event.target.value =
                                                event.target.value.replace(
                                                    /[^0-9]*/g,
                                                    ""
                                                )
                                            setTokenSupply(event.target.value)
                                        }}
                                    />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                <Button
                                    backgroundColor="brand.300"
                                    color={"white"}
                                    onClick={editTokenMaximum}
                                >
                                    Change
                                </Button>
                            </HStack>
                            <Divider marginTop={5} marginBottom={5} />
                            <FormLabel>Change Mint Limit</FormLabel>
                            <HStack>
                                <NumberInput min={0} flex="1">
                                    <NumberInputField
                                        id="amount"
                                        placeholder="how many tokens per user"
                                        value={mintLimit}
                                        onChange={event => {
                                            event.target.value =
                                                event.target.value.replace(
                                                    /[^0-9]*/g,
                                                    ""
                                                )
                                            setMintLimit(event.target.value)
                                        }}
                                    />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                <Button
                                    backgroundColor="brand.300"
                                    color={"white"}
                                    onClick={editTokenMintLimit}
                                >
                                    Change
                                </Button>
                            </HStack>
                            <Divider marginTop={5} marginBottom={5} />
                            <FormLabel>Change Metadata</FormLabel>
                            <HStack>
                                <Input
                                    flex="1"
                                    placeholder="ipfs hash"
                                    value={metadata}
                                    onChange={event => {
                                        setMetadata(event.target.value)
                                    }}
                                />
                                <Button
                                    backgroundColor="brand.300"
                                    color={"white"}
                                    onClick={editTokenIPFS}
                                >
                                    Change
                                </Button>
                            </HStack>
                        </FormControl>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
