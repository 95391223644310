import * as React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import NewMint from './routes/new-mint';
import EditMint from './routes/edit-mint';
import Withdraw from './routes/withdraw';
import Settings from './routes/settings';
import Members from './routes/members';
import { useSafeAppsSDK } from '@gnosis.pm/safe-apps-react-sdk';
import { constants, ethers } from 'ethers';
import abi from './abi.json';
import abiClub from './abiClub.json';

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const provider = new ethers.providers.InfuraProvider('homestead', process.env.REACT_APP_INFURA_KEY);
const contract = new ethers.Contract(contractAddress, abi, provider);

function Main() {
    const { sdk, connected, safe } = useSafeAppsSDK();
    const [clubContract, setClubContract] = useState(null);

    useEffect(() => {
        async function getClubAddress() {
            const clubAddress = await contract.clubs(safe.safeAddress);
            if (clubAddress !== constants.AddressZero) {
                setClubContract(new ethers.Contract(clubAddress, abiClub, provider));
            }
        }

        getClubAddress()
    }, [connected, safe])


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App safeAddress={safe.safeAddress} connected={connected} />}>
                    <Route path="new-mint" element={<NewMint contract={clubContract} sdk={sdk} />} />
                    <Route path="edit-mint" element={<EditMint contract={clubContract} sdk={sdk} />} />
                    <Route path="withdraw" element={<Withdraw contract={clubContract} sdk={sdk} />} />
                    <Route path="members" element={<Members contract={clubContract} sdk={sdk} />} />
                    <Route path="settings" element={<Settings contract={clubContract} sdk={sdk} />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default Main;