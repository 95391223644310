import * as React from "react"
import { useEffect, useState } from "react"
import { useMoralisWeb3Api } from "react-moralis"

import { Flex, VStack, Heading } from "@chakra-ui/react"

import FundingRound from "../components/funding-round"

export default function EditMint(props) {
    const web3Api = useMoralisWeb3Api()
    const [data, setData] = useState()

    useEffect(() => {
        if (props.contract) {
            web3Api.token
                .getAllTokenIds({ address: props.contract.address })
                .then(result => {
                    setData(result.result)
                })
        }
    }, [props.contract, web3Api.token])

    return (
        <VStack p={20} gap="5vw">
            <Heading>Funding Rounds</Heading>
            <Flex align="center" justify="center" gap="5vw" flexWrap="wrap">
                {data ? (
                    data.map((token, index) => {
                        return (
                            <FundingRound
                                id={index}
                                metadata={JSON.parse(token.metadata)}
                                contract={props.contract}
                            />
                        )
                    })
                ) : (
                    <div>Loading...</div>
                )}
            </Flex>
        </VStack>
    )
}
