import * as React from "react"
import { useState, useEffect } from "react"

import {
    Flex,
    Button,
    Input,
    VStack,
    HStack,
    Box,
    Heading,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
} from "@chakra-ui/react"

import { useMoralisWeb3Api } from "react-moralis"
import KickMember from "../components/kick-member"
import AddMember from "../components/add-member"

export default function Members(props) {
    const [members, setMembers] = useState()
    const [date, setDate] = useState(new Date().toISOString())
    const [block, setBlock] = useState(0)
    const web3Api = useMoralisWeb3Api()

    useEffect(() => {
        if (props.contract) {
            const fetchNFTOwners = async () => {
                const options = { address: props.contract.address }
                const nftOwners = await web3Api.token.getNFTOwners(options)
                setMembers(nftOwners.result)
            }

            fetchNFTOwners()
        }
    }, [props.contract, web3Api.token])

    useEffect(() => {
        const fetchBlockDate = async () => {
            const options = { date: date }
            setBlock(await web3Api.native.getDateToBlock(options))
        }

        fetchBlockDate()
    }, [date, web3Api.native])

    const download = () => {
        let csv = []

        members.forEach(member => {
            if (member.block_number <= block.block) {
                csv.push({
                    address: member.owner_of,
                    balance: member.amount,
                    block: member.block_number,
                })
            }
        })

        const csvData = new Blob([JSON.stringify(csv)], {
            type: "text/csv;charset=utf-8;",
        })
        const csvURL = URL.createObjectURL(csvData)
        const tempLink = document.createElement("a")
        tempLink.href = csvURL
        tempLink.setAttribute("download", "members.json")
        tempLink.click()
    }

    const downloadCSV = () => {
        let csv = "address, balance, block\n"

        members.forEach(member => {
            if (member.block_number <= block.block) {
                const line = [
                    member.owner_of,
                    member.amount,
                    member.block_number,
                ].join()
                csv += line + "\n"
            }
        })

        const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" })
        const csvURL = URL.createObjectURL(csvData)
        const tempLink = document.createElement("a")
        tempLink.href = csvURL
        tempLink.setAttribute("download", "members.csv")
        tempLink.click()
    }

    return (
        <VStack>
            <Flex
                align="flex-start"
                width="100%"
                paddingTop={10}
                paddingLeft={10}
            >
                <Input
                    width="300px"
                    type="datetime-local"
                    onChange={event => {
                        setDate(event.target.value)
                    }}
                />
            </Flex>
            <VStack width="100%">
                <Heading>Member List for {date.slice(0, 10)}</Heading>
                <Box overflow="auto" maxH="50vh" width="100%">
                    <Table colorScheme="linkedin">
                        <Thead>
                            <Tr>
                                <Th width="40vw">Address</Th>
                                <Th>Owns</Th>
                                <Th>Joined Block</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {members ? (
                                members.map(member => {
                                    return parseInt(member.block_number) <=
                                        block.block ? (
                                        <Tr>
                                            <Td>{member.owner_of}</Td>
                                            <Td>{member.amount}</Td>
                                            <Td>{member.block_number}</Td>
                                            <Td>
                                                <KickMember
                                                    contract={props.contract}
                                                    address={member.owner_of}
                                                />
                                            </Td>
                                        </Tr>
                                    ) : null
                                })
                            ) : (
                                <Tr>Loading...</Tr>
                            )}
                        </Tbody>
                    </Table>
                </Box>
                <Flex
                    width="100%"
                    paddingLeft={10}
                    paddingRight={10}
                    justify="space-between"
                >
                    <HStack>
                        <Button bgColor="brand.300" onClick={downloadCSV}>
                            Download CSV
                        </Button>
                        <Button bgColor="brand.300" onClick={download}>
                            Download JSON
                        </Button>
                    </HStack>
                    <AddMember contract={props.contract} />
                </Flex>
            </VStack>
        </VStack>
    )
}
