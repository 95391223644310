import {
    VStack,
    HStack,
    Text,
    Button,
    Img,
    useDisclosure,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"

import { formatEther } from "ethers/lib/utils"
import EditMintModal from "./edit-mint-modal"
import SettingsModal from "./settings-modal"

export default function FundingRound(props) {
    const [price, setPrice] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    const {
        isOpen: isEditOpen,
        onOpen: onEditOpen,
        onClose: onEditClose,
    } = useDisclosure()

    const {
        isOpen: isSettingsOpen,
        onOpen: onSettingsOpen,
        onClose: onSettingsClose,
    } = useDisclosure()

    useEffect(() => {
        props.contract.tokens(props.id).then(result => {
            setPrice(formatEther(result.mintPrice))
            setStartDate(
                new Date(result.startTime.toString() * 1000)
                    .toISOString()
                    .slice(0, 10)
            )
            setEndDate(
                new Date(result.endTime.toString() * 1000)
                    .toISOString()
                    .slice(0, 10)
            )
        })
    }, [props.contract, props.id])

    return (
        <>
            <VStack
                backgroundColor="brand.300"
                borderRadius="12px"
                padding="16px"
            >
                <Text>{props.metadata.name}</Text>
                <Img
                    width="10vw"
                    src={`https://ipfs.io/ipfs/${props.metadata.image.replace(
                        "ipfs://",
                        ""
                    )}`}
                ></Img>
                <Text>Ξ{price}</Text>
                <Text>
                    {startDate} - {endDate}
                </Text>
                <HStack>
                    <Button color="brand.100" onClick={onEditOpen}>
                        Edit
                    </Button>
                    <Button
                        color="brand.100"
                        onClick={() =>
                            window.open(
                                `https://coterie.capital/mint/${props.contract.address}?token=${props.id}`
                            )
                        }
                    >
                        Mint
                    </Button>
                    <Button color="brand.100" onClick={onSettingsOpen}>
                        Settings
                    </Button>
                </HStack>
            </VStack>
            <EditMintModal
                contract={props.contract}
                id={props.id}
                name={props.metadata.name}
                onClose={onEditClose}
                isOpen={isEditOpen}
            />
            <SettingsModal
                contract={props.contract}
                id={props.id}
                name={props.metadata.name}
                onClose={onSettingsClose}
                isOpen={isSettingsOpen}
            />
        </>
    )
}
