import React from 'react';

import {
  Box,
  Text,
  Code,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';

import {
  HamburgerIcon,
} from '@chakra-ui/icons';

import { Link, Outlet } from 'react-router-dom';
import { Logo } from './Logo';
import { useState } from 'react';

function App(props) {
  const [width, setWidth] = useState(0);

  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
  });

  return (
    <Box height='100vh' color='white' background="brand.100" textAlign="center" fontSize="xl">

      {width > 1232 ?
        < Flex flex='1' flexWrap='wrap' background="brand.200" p={4} align='center' justify='space-between'>
          <Logo screenwidth={width}></Logo>
          <Flex color='white' align='center' gap={20} flexWrap='wrap' paddingLeft={20} paddingRight={20} >
            <Link to="/new-mint">New mint</Link>
            <Link to="/edit-mint">Edit mint</Link>
            <Link to="/withdraw">Withdraw funds</Link>
            <Link to="/members">Members</Link>
            <Link to="/settings">Settings</Link>
          </Flex>
          <Text align={'center'}>
            <Code fontSize='lg'>{props.connected ? "connected" : "not connected"}</Code>
          </Text>
        </Flex> :
        <Flex flex='1' flexWrap='wrap' background="brand.200" p={4} align='center' justify='space-between'>
          <Logo screenwidth={width}></Logo>
          <Text align={'center'}>
            <Code fontSize='lg'>{props.connected ? "connected" : "not connected"}</Code>
          </Text>
          <Menu >
            <MenuButton as={HamburgerIcon} >Open menu</MenuButton>
            <MenuList color='brand.100'>
              <MenuItem><Link to="/new-mint">New mint</Link></MenuItem>
              <MenuItem><Link to="/edit-mint">Edit mint</Link></MenuItem>
              <MenuItem><Link to="/withdraw">Withdraw funds</Link></MenuItem>
              <MenuItem><Link to="/members">Members</Link></MenuItem>
              <MenuItem><Link to="/settings">Settings</Link></MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      }
      <Outlet />
    </Box >
  );
}

export default App;
