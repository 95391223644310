import * as React from 'react';
import { useState } from 'react';

import {
    Flex,
    Button,
    Input,
    VStack,
    HStack,
    FormControl,
    FormLabel,
    FormHelperText,
    NumberInputField,
    NumberInput,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Divider,
    useToast,
    Heading,
} from '@chakra-ui/react';

import { parseEther } from 'ethers/lib/utils';

export default function NewMint(props) {
    const [mintPrice, setMintPrice] = useState(0);
    const [startDate, setStartDate] = useState(0);
    const [tokenSupply, setTokenSupply] = useState(0);
    const [endDate, setEndDate] = useState(0);
    const [ipfsCID, setIpfsCID] = useState('');
    const [maxAmount, setMaxAmount] = useState(0);
    const toast = useToast()

    const createFundingRound = async () => {
        if (!mintPrice || !startDate || !endDate || !ipfsCID || !tokenSupply || !maxAmount) {
            toast({
                title: 'Please fill in all fields',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })

            return
        }


        const startDateUnix = Math.floor(new Date(startDate).getTime() / 1000)
        const endDateUnix = Math.floor(new Date(endDate).getTime() / 1000)

        const txs = [
            {
                to: props.contract.address,
                value: '0',
                data: props.contract.interface.encodeFunctionData('createFundingRound', [parseEther(mintPrice), startDateUnix, endDateUnix, tokenSupply, maxAmount, ipfsCID]),
            }
        ]

        try {
            const txn = await props.sdk.txs.send({ txs })
            console.log(txn)
        } catch (e) {
            toast({
                title: 'Something went wrong',
                description: e.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
    }

    return (
        <Flex align='center' justify='center' p='20' background="brand.100" color={'white'} padding={10} borderRadius={10}>
            <Flex direction={'column'}>
                <Heading color='white'>Create a new token</Heading>
                <Flex p={5}>
                    <FormControl>
                        <FormLabel>Mint Price</FormLabel>
                        <NumberInput min={0}>
                            <NumberInputField id='amount' value={mintPrice} onChange={(event) => {
                                event.target.value = event.target.value.replace(/[^0-9]*/, '')
                                setMintPrice(event.target.value)
                            }} />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <FormHelperText>Enter value in Ether</FormHelperText>
                        <Divider marginTop={5} marginBottom={5} />
                        <HStack>
                            <VStack>
                                <FormLabel>Start Time</FormLabel>
                                <Input type="datetime-local" _placeholder={{ color: 'lightgray' }} placeholder='Start time' onChange={(event) => { setStartDate(event.target.value) }} />
                                <FormHelperText>Select date and time minting will start</FormHelperText>
                            </VStack>
                            <VStack>
                                <FormLabel>End Time</FormLabel>
                                <Input type="datetime-local" onChange={(event) => { setEndDate(event.target.value) }} />
                                <FormHelperText>Select date and time minting will end</FormHelperText>
                            </VStack>
                        </HStack>
                        <Divider marginTop={5} marginBottom={5} />
                        <FormLabel>Token Supply</FormLabel>
                        <NumberInput min={0}>
                            <NumberInputField id='amount' value={tokenSupply} onChange={(event) => {
                                event.target.value = event.target.value.replace(/[^0-9]*/g, '')
                                setTokenSupply(event.target.value)
                            }} />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <FormHelperText>Enter '0' for unlimited token supply</FormHelperText>
                        <Divider marginTop={5} marginBottom={5} />
                        <FormLabel>Mint Limit</FormLabel>
                        <NumberInput min={0}>
                            <NumberInputField id='amount' value={maxAmount} onChange={(event) => {
                                event.target.value = event.target.value.replace(/[^0-9]*/g, '')
                                setMaxAmount(event.target.value)
                            }} />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <FormHelperText>How many tokens each member can mint. Enter '0' for no limit. </FormHelperText>
                        <Divider marginTop={5} marginBottom={5} />
                        <FormLabel>Token Metadata</FormLabel>
                        <Input onChange={(event) => { setIpfsCID(event.target.value) }} />
                        <FormHelperText>Enter IPFS CID of the token metadata</FormHelperText>
                        <Divider marginTop={5} marginBottom={5} />
                    </FormControl>
                </Flex>
                <Button backgroundColor="brand.300" color={'white'} onClick={createFundingRound}>Create</Button>
            </Flex>
        </Flex >
    );
}