import * as React from 'react';
import { useState, useEffect } from 'react';

import {
    Flex,
    Button,
    Heading,
    Text,
    Box,
    useToast,
} from '@chakra-ui/react';

import { formatEther } from 'ethers/lib/utils';

export default function Withdraw(props) {
    const [contractBalance, setContractBalance] = useState(0);
    const toast = useToast()

    async function getContractBalance() {
        const balance = await props.contract.provider.getBalance(props.contract.address)
        setContractBalance(formatEther(balance))
    }

    useEffect(() => {
        getContractBalance()
    }, [])

    const withdraw = async () => {
        const txs = [
            {
                to: props.contract.address,
                value: '0',
                data: props.contract.interface.encodeFunctionData('withdraw', []),
            },
        ]

        try {
            const txn = await props.sdk.txs.send({ txs })
            console.log(txn)
        } catch (e) {
            toast({
                title: 'Something went wrong',
                description: e.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }

    }

    return (
        <Flex justify='center' p='20'>
            <Box background='brand.100' padding={10} borderRadius={10}>
                <Heading color='white'>Withdraw</Heading>
                <Text color='white'>Transfer mint proceeds to the safe</Text>
                {contractBalance <= 0 ? <Text color='white'>No mint proceeds available</Text> : <Text color='white'>Ξ{contractBalance} of mint proceeds are available to withdraw</Text>}
                <Button marginTop={5} backgroundColor='brand.300' color={'white'} onClick={withdraw} isDisabled={contractBalance <= 0}>Withdraw</Button>
                <Text marginTop='5vh' fontSize='sm' color='darkgray'><a href="https://coterie.notaku.site/fee-structure-103c3ef258eb4fd08f22093ee0f9f536">Coterie Capital takes a small percentage of mint proceeds to fund development. Click here to view fee structure.</a></Text>
            </Box>
        </Flex >
    );
}