import React, { useEffect, useState } from "react"

import {
    Box,
    Text,
    Flex,
    Button,
    Input,
    VStack,
    HStack,
    Heading,
    useToast,
} from "@chakra-ui/react"

export default function Settings(props) {
    const [maxMembers, setMaxMembers] = useState()
    const toast = useToast()

    const editMaximumMembers = async () => {
        if (!maxMembers) {
            toast({
                title: "Maximum members is required",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
            return
        }

        const txs = [
            {
                to: props.contract.address,
                value: "0",
                data: props.contract.interface.encodeFunctionData(
                    "editMaximumMembers",
                    [maxMembers]
                ),
            },
        ]

        try {
            const txn = await props.sdk.txs.send({ txs })
            console.log(txn)
        } catch (e) {
            toast({
                title: "Something went wrong",
                description: e.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    }

    return (
        <Flex justify="center" p="20" gap="1vw">
            <VStack>
                <Box
                    background="brand.100"
                    color={"white"}
                    padding={10}
                    borderRadius={10}
                >
                    <VStack>
                        <Heading>DAO Settings</Heading>
                        <Text>Update maximum members</Text>
                        <HStack>
                            <Input
                                type="number"
                                placeholder="New maximum members"
                                value={maxMembers}
                                onChange={event => {
                                    setMaxMembers(
                                        event.target.value.replace(
                                            /[^0-9]*/g,
                                            ""
                                        )
                                    )
                                }}
                            />
                            <Button
                                backgroundColor="brand.300"
                                color={"white"}
                                onClick={editMaximumMembers}
                            >
                                Change
                            </Button>
                        </HStack>
                    </VStack>
                </Box>
            </VStack>
        </Flex>
    )
}
